<script>
import TablePage from '@/found/components/table_page';
// import { Form, ShuttleBox } from '../form';
import selectTemplate from '../form/select_template.vue';
import Form from '../../contract_template/form/form.vue';

export default {
  extends: TablePage,
  components: {
    selectTemplate,
    Form,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('dms_contract_manage');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'add') {
        this.modalConfig.title = '新增合同';
        this.modalConfig.width = '800px';
        this.modalConfig.showFooter = true;
        this.modalConfig.buttons = {
          sure: true,
          close: true,
        };
        this.formName = 'selectTemplate';
        this.openModal();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑合同';
        this.formName = 'Form';
        this.modalConfig.showFooter = false;
        this.formConfig = row;
        this.formConfig.templateCode = row.templateCode;
        this.formConfig.formType = 'edit';
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看合同';
        this.formName = 'Form';
        this.modalConfig.showFooter = false;
        this.formConfig = row;
        this.formConfig.templateCode = row.templateCode;
        this.formConfig.formType = 'view';
        this.openFull();
      }
    },
    onModalOk(val) {
      if (this.formName === 'selectTemplate') {
        this.modalConfig.visible = false;
        const selectTemp = this.$refs.modalForm.$refs.xTable.getRadioRecord();
        if (selectTemp) {
          this.modalConfig.width = '';
          this.formName = 'Form';
          this.modalConfig.showFooter = false;
          this.formConfig = selectTemp;
          this.formConfig.templateCode = selectTemp.code;
          this.openFull();
        }
      }
    },
  },
};
</script>
